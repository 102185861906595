import { html, nothing, render } from "lit-html";
import { translate } from "@escapio/shared/l10n/translate";
import { hasSignedUp } from "./newsletter/popupTracker";

customElements.define(
	"esc-footer-item-link-newsletter",
	class extends HTMLLIElement {
		connectedCallback() {
			render(
				hasSignedUp()
					? nothing
					: html`<esc-click-tracker
							category="Footer"
							action="Link geklickt"
							label="Newsletter - ${this.pageType}"
							use-event-queue
						>
							<a class="footer-links-desktop__link" href=${this.href}
								>${translate("footer_service_newsletter_label")}
							</a>
						</esc-click-tracker>`,
				this,
			);
		}

		get pageType() {
			return this.getAttribute("page-type");
		}

		get href() {
			return this.getAttribute("href");
		}
	},
	{ extends: "li" },
);
